import { useEffect } from "react";
import { setTitle } from "../helpers/title";
import { Helmet } from "react-helmet";

function Insursync() {
	useEffect(() => {
		setTitle(`Insursync | MyHealthily - Affordable Healthcare for All`);
	}, []);
	
	return (
		<div role="main" className="main">
			
			<section class="page-header page-header-modern bg-color-light-scale-1 page-header-sm">
				<div class="container">
					<div class="row">
						<div class="col-md-8 align-self-center p-static order-2 order-md-1">
						<img src="img/insursync-logo.png" alt="MyHealthily Partners" width="200" height="48" data-sticky-width="182" data-sticky-height="40"/>
						</div>
						<div class="col-md-4 align-self-center order-1 order-md-2">
							<ul class="breadcrumb d-block text-md-end">
								<li><a href="/insursync-schedule"><strong>Schedule a Call</strong></a></li>
							</ul>
						</div>
					</div>
				</div>
			</section>			
			
			<div className="container container-xl-custom py-1 my-1 pt-4">
				<div className="row justify-content-center">
					<div className="col-xl-9 text-center">
						<h2 className="font-weight-bold text-11 appear-animation" data-appear-animation="fadeInUpShorter">Group Healthcare</h2>
						<p className="line-height-9 text-4 opacity-9 appear-animation"
						   data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">Insursync has partnered with <a
						   href="https://myhealthily.com" target="_new">MyHealthily</a> to bring our clients affordable healthcare options like never before! Complete coverage for small businesses includes healthcare, dental, vision, disability, life, and more!</p>
					</div>
				</div>
			</div>

			<div className="container pt-1">
				<div className="row mt-5">
					<div className="col">
						<h4 className="mb-2" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="900">Save Up to 30% in 3 Easy Steps!</h4>
						<div className="row process process-shapes my-5">
							<div className="process-step col-lg-4 mb-5 mb-lg-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
								<div className="process-step-circle">
									<strong className="process-step-circle-content">1</strong>
								</div>
								<div className="process-step-content">
									<h4 className="mb-1 text-5 font-weight-bold">SCHEDULE A CALL</h4>
									<p className="mb-0">Select a time and date that works for you to find the best plan option that works for you with a trusted advisor.</p>
									<a href="/insursync-schedule" className="btn btn-primary mt-2">Schedule a Call</a>
								</div>
							</div>
							<div className="process-step col-lg-4 mb-5 mb-lg-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">
								<div className="process-step-circle">
									<strong className="process-step-circle-content">2</strong>
								</div>
								<div className="process-step-content">
									<h4 className="mb-1 text-5 font-weight-bold">COMPLETE THE CENSUS</h4>
									<p className="mb-0">After you schedule a call, we will email you a <a href="https://app.myhealthily.com/MyHealthily.com-census.xlsx" target="_new">Census</a> (Excel) to fill out and return to us. We will use this info to generate the the best available plans for you.</p>
								</div>
							</div>
							<div className="process-step col-lg-4 mb-5 mb-lg-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600">
								<div className="process-step-circle">
									<strong className="process-step-circle-content">3</strong>
								</div>
								<div className="process-step-content">
									<h4 className="mb-1 text-5 font-weight-bold">ATTEND THE CALL</h4>
									<p className="mb-0">A trusted advisor will walk you through plans based on your completed census, answer your questions, and help you with the enrollment process.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<section className="section bg-color-grey-scale-1 section-height-3 border-0 m-0">
				<div className="container">
					<div className="row mb-lg-4">
						<div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">
							<div className="feature-box feature-box-style-2">
								<div className="feature-box-icon">
									<i className="icons icon-clock text-color-primary"></i>
								</div>
								<div className="feature-box-info">
									<h4 className="font-weight-bold mb-2">PART-TIME & SEASONAL PLANS</h4>
									<p>We have many affordable healthcare options for part-time and seasonal workers.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 appear-animation" data-appear-animation="fadeInUpShorter">
							<div className="feature-box feature-box-style-2">
								<div className="feature-box-icon">
									<i className="icons icon-umbrella text-color-primary"></i>
								</div>
								<div className="feature-box-info">
									<h4 className="font-weight-bold mb-2">NATIONAL CARRIERS</h4>
									<p>We work with more than 135 carriers to offer the best plans and options for every need.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
							<div className="feature-box feature-box-style-2">
								<div className="feature-box-icon">
									<i className="icons icon-map text-color-primary"></i>
								</div>
								<div className="feature-box-info">
									<h4 className="font-weight-bold mb-2">COVERAGE ANYWHERE</h4>
									<p>Coast to coast coverage! Groups can find healthcare and ancillary plans in all 50 states.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">
							<div className="feature-box feature-box-style-2">
								<div className="feature-box-icon">
									<i className="icons icon-star text-color-primary"></i>
								</div>
								<div className="feature-box-info">
									<h4 className="font-weight-bold mb-2">WOW CUSTOMER SERVICE</h4>
									<p>Our advisors provide WOW customer service while guiding you through the enrollment process.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 appear-animation" data-appear-animation="fadeInUpShorter">
							<div className="feature-box feature-box-style-2">
								<div className="feature-box-icon">
									<i className="icons icon-check text-color-primary"></i>
								</div>
								<div className="feature-box-info">
									<h4 className="font-weight-bold mb-2">FULLY COMPLIANT</h4>
									<p>Coverage can include HSA / FSA / Travel reimbursement all while adhering to State and Federal laws.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
							<div className="feature-box feature-box-style-2">
								<div className="feature-box-icon">
									<i className="icons icon-screen-desktop text-color-primary"></i>
								</div>
								<div className="feature-box-info">
									<h4 className="font-weight-bold mb-2">EASY TO USE WEBSITE</h4>
									<p>We’ve developed a straight-forward user experience that makes enrollment a breeze.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			
			<section className="call-to-action call-to-action-primary mb-5">
				<div className="container">
					<div className="row">
						<div className="col-sm-9 col-lg-9">
							<div className="call-to-action-content">
							<h3><strong>Get a Quote!</strong></h3>
							<p className="mb-0">Schedule an appointment to review hundreds of plans, and work with our advisors to find the right coverage for you and your employees.</p>
							</div>
						</div>
						<div className="col-sm-3 col-lg-3">
							<div className="call-to-action-btn">
								<a href="/insursync-schedule" className="btn btn-modern text-2 btn-light border-0">Schedule a Call</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			
			<div className="container">
				<div className="row my-3"></div>
				<div className="row my-5 pb-2">
					<div className="col-lg-4">
						
						<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
							<div className="card-body py-3 px-2">
								<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
									<div className="testimonial-author pb-3">
										<div className="testimonial-author-thumbnail">
											<img src="img/logo-mjc.png" className="img-fluid rounded-circle" alt=""/>
										</div>
										<p><strong className="font-weight-bold">Christy J.</strong><span>Matthew Johns Construction</span>
										</p>
									</div>
									<div className="px-4 mx-2 pt-2 pb-3">
										<h4 className="font-weight-semi-bold">More Plan Options</h4>
										<p>After years of feeling limited in regards to healthcare options and customer service, we are extremely satisfied with John David and MyHealthily.</p>
										<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i></p>
									</div>
								</div>
							</div>
						</div>
					
					</div>
					<div className="col-lg-4">
						
						<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
							<div className="card-body py-3 px-2">
								<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
									<div className="testimonial-author pb-3">
										<div className="testimonial-author-thumbnail">
											<img src="img/logo-game-crafter.png" className="img-fluid rounded-circle"
												 alt=""/>
										</div>
										<p><strong className="font-weight-bold">Travis Jamie</strong><span>Owner, The Game Crafter</span>
										</p>
									</div>
									<div className="px-4 mx-2 pt-2 pb-3">
										<h4 className="font-weight-semi-bold">Big Savings</h4>
										<p>It is a breath of fresh air to speak to someone about real options that are less than $300 per employee. Thanks, MyHealthily!</p>
										<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i></p>
									</div>
								</div>
							</div>
						</div>
					
					</div>
					<div className="col-lg-4">
						
						<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
							<div className="card-body py-3 px-2">
								<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
									<div className="testimonial-author pb-3">
										<div className="testimonial-author-thumbnail">
											<img src="img/logo-mfb.png" className="img-fluid rounded-circle" alt=""/>
										</div>
										<p><strong className="font-weight-bold">John Torres</strong><span>Executive Director, Maryland Farm Bureau</span>
										</p>
									</div>
									<div className="px-4 mx-2 pt-2 pb-3">
										<h4 className="font-weight-semi-bold">Wow Customer Service</h4>
										<p>Just got off the phone with your Broker Representative. She was great. Mad props to her customer service skills.</p>
										<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i></p>
									</div>
								</div>
							</div>
						</div>
					
					</div>
				</div>
			</div>

			{/* Carriers */}
			<section className="call-to-action">
				<div className="container">
					<div className="row text-center pb-2 mt-4">
						<h4>135+ National Carriers</h4>
						<div className="owl-carousel owl-theme carousel-center-active-item mb-0" data-plugin-options="{'responsive': {'0': {'items': 1}, '476': {'items': 1}, '768': {'items': 5}, '992': {'items': 7}, '1200': {'items': 5}}, 'autoplay': true, 'autoplayTimeout': 1500, 'dots': false}">
							<div><img className="img-fluid" src="/img/allstate.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/aflac.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/aetna.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/united.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/sidecar.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/principal.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/oscar.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/kaiser-permanente.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/humana.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/guardian-healthcare.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/friday-health-plans.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/cigna.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/careington.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/blue-cross.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/beam.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/anthem.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/and-many-more.jpg" alt=""/></div>
						</div>
					</div>
				</div>
			</section>
			
			<section className="call-to-action call-to-action-default with-button-arrow call-to-action-in-footer">
				<div className="container">
					<div className="row">
						<div className="col-sm-9 col-lg-9">
							<div className="call-to-action-content">
								<h3>Schedule a Call</h3>
								<p className="mb-0">Give us a few minutes to walk you through our platform, answer questions, and get you signed up!</p>
							</div>
						</div>
						<div className="col-sm-3 col-lg-3">
							<div className="call-to-action-btn">
								<a href="/insursync-schedule"
								   className="btn btn-modern text-2 btn-primary">Get a Quote</a><span
								className="arrow hlb d-none d-md-block" data-appear-animation="rotateInUpLeft"
								style={{ "left": "110%", top: "-40px" }}></span>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Helmet>
				<script src="https://cdn.pagesense.io/js/candorusa/4456c48b933f4a539edc609cbc6838fa.js"></script>
			</Helmet>
		
		</div>
	);
}

export default Insursync;
