import { initPartners } from "./PartnersModel";

const PartnersThemes = {
	default: initPartners()
		.setName("MyHealthily Partners")
		.setLabel ("Myh-partners")
		.setLogo("myh-partners-logo.png")
		.setAssocLogo("assn-chambers-jewlers.jpg"),
};

export var Partners = PartnersThemes.default;

export function setTheme(theme) {
	if (PartnersThemes[theme]) {
		Partners = PartnersThemes[theme];
	} else {
		Partners = PartnersThemes.default;
	}
	return Partners;
}
