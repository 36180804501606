import { useEffect } from "react";
import { setTitle } from "../helpers/title";
import { Partners } from "../helpers/partners";
import { Helmet } from "react-helmet";

function IsdshealthSchedule() {
	useEffect(() => {
		setTitle(`TDICIS  | ISDS - Affordable Healthcare for All`);
	}, []);
	
	return (
		<div role="main" className="main">
			
			<section class="page-header page-header-modern bg-color-light-scale-1 page-header-sm">
				<div class="container">
					<div class="row">
						<div class="col-md-8 align-self-center p-static order-2 order-md-1">
						<img src="img/tdic-logo.png" alt="TDICIS " width="200" height="48" data-sticky-width="182" data-sticky-height="40"/>
						</div>
						<div class="col-md-4 align-self-center order-1 order-md-2">
							<ul class="breadcrumb d-block text-md-end">
								<li><a href="/insursync-schedule"><strong>Schedule a Call</strong></a></li>
							</ul>
						</div>
					</div>
				</div>
			</section>
			
			<div className="container container-xl-custom py-1 my-1">
				<div className="row justify-content-center">
					<div className="col-xl-9 text-center">
						<h2 className="font-weight-bold text-11 appear-animation"
							data-appear-animation="fadeInUpShorter">Expert Guidance</h2>
						<p className="line-height-9 text-4 opacity-9 appear-animation"
						   data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">Our trusted advisors
							will help answer questions and walk you through the enrollment process.</p>
					</div>
				</div>
			</div>
			
			<div className="container">
				<div className="row text-center text-md-start pt-4 pb-5 my-4">
					<div className="col-lg-6 mb-5 mb-lg-0 appear-animation" data-appear-animation="fadeInRightShorter"
						 data-appear-animation-delay="600">
						<h2 className="font-weight-bold text-6 line-height-1 ls-0 mb-4">Get an Accurate Quote</h2>
						<p>Health insurance is rated on a combination of date of birth, home zip code, and birth gender.
							To receive an accurate quote for your business, you must complete a census before your scheduled call.</p>
						<p><a href="https://app.myhealthily.com/MyHealthily.com-census.xlsx"
							  style={{ "textDecoration": "underline"}} target="_new" rel="noopener">Download
							Census</a> (Excel)</p>
						<hr/>
						
						<h4>What’s a Census?</h4>
						<p>A census is a snapshot of your company’s eligible employees. It includes all Employee’s
							Name and the names of any family members, Birth Gender, Date of Birth, Zip Code, Tobacco
							Use and Email Address. By providing this information, we can give you the most accurate
							quote for your company’s healthcare coverage costs. And don’t worry – we will not sell
							your data, and we’ll keep it secure.</p>
						<hr/>
						
						<h4>How Do I Fill Out My Census?</h4>
						<p>Watch a <a href="https://share.getcloudapp.com/jkumxLbw"
									  style={{ "textDecoration": "underline"}}  target="_new" rel="noopener">short
							video</a> on how to quickly fill out your census.</p>
						<hr/>
						
						<h4>About Your Plan​</h4>
						<p>You are not joining a group healthcare plan that is part of an association.​ Our
							licensed advisors will help you narrow down the right coverage for you, based on
							your specific needs.</p>
					
					</div>
					<div className="col-lg-6 mb-5 mb-lg-0 appear-animation" data-appear-animation="fadeInRightShorter"
						 data-appear-animation-delay="1000">
						<h2 className="font-weight-bold text-6 line-height-1 ls-0 mb-4">Schedule a Call</h2>
						<p>Schedule a date and time that works for you.</p>
						<iframe loading="lazy" width="100%" height="800px"
								src="https://zfrmz.com/zaI8xSZI7uT50VzEeQjO"
								frameBorder="0" allowFullScreen=""></iframe>
					</div>
				</div>
			</div>

			<Helmet>
				<script src="https://cdn.pagesense.io/js/candorusa/4456c48b933f4a539edc609cbc6838fa.js"></script>
			</Helmet>
		
		</div>
	);
}

export default IsdshealthSchedule;
