import { useEffect } from "react";
import { setTitle } from "../helpers/title";
import { Partners } from "../helpers/partners";
import { Helmet } from "react-helmet";

function Home() {
	useEffect(() => {
		setTitle(`${Partners.name} - Healthcare, Dental, Vision, and More!`);
	}, []);
	
	return (
		<div role="main" className="main">

			<section class="page-header page-header-modern bg-color-light-scale-1 page-header-sm">
				<div class="container">
					<div class="row">
						<div class="col-md-8 align-self-center p-static order-2 order-md-1">
						<img src="img/myh-partners-logo.png" alt="MyHealthily Partners" width="200" height="48" data-sticky-width="182" data-sticky-height="40"/>
						</div>
						<div class="col-md-4 align-self-center order-1 order-md-2">
							&nbsp;
						</div>
					</div>
				</div>
			</section>

			<section id="who-we-are" className="section section-height-3 bg-transparent border-0 m-0">
				<div className="container py-4">
					<div className="row align-items-center justify-content-center">
						<div className="col-lg-6 pe-lg-5 mb-5 mb-lg-0">
							<div className="overflow-hidden">
								<h2 className="text-color-primary font-weight-semibold text-3 line-height-7 positive-ls-2 mb-0 appear-animation" data-appear-animation="maskUp">SMALL GROUP HEALTHCARE SOLUTIONS</h2>
							</div>
							<div className="overflow-hidden mb-3">
								<h3 className="text-color-dark font-weight-bold text-transform-none line-height-1 text-10 mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">MyHealthily Partners</h3>
							</div>
							<p className="text-3-5 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600">MyHealthily works with agents and brokers across America to offer affordable healthcare and ancillary products to small businesses.</p>
						</div>
						<div className="col-5 col-sm-7 col-md-12 col-lg-6 text-center">
							<div className="position-relative d-inline-flex">
								<img src="img/icon-myhealthily.png" className="home-left-padding img-fluid rounded-circle box-shadow-5 position-absolute top-50pct transform3dy-n50 z-index-3" alt="" />
								<img src="img/icon-insurance.png" className="img-fluid rounded-circle box-shadow-5 z-index-2" width="260" alt="" />
								<img src="img/icon-dental-health.png" className="home-right-padding img-fluid rounded-circle box-shadow-5 position-absolute top-50pct transform3dy-n50 z-index-1" alt="" />
							</div>
						</div>
					</div>
				</div>
			</section>

			<div className="container">
				<div className="row py-5 my-5">
					<div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter">
						<h2 className="font-weight-normal text-6 line-heigh-2 mb-4"><strong className="font-weight-extra-bold">Who We Are</strong></h2>
						<p>MyHealthily was founded by Insurance veteran, Michael Malhame, who noticed major challenges with small group healthcare. Brokers find it difficult to profitably write business, and the plans they offer are far too expensive for small businesses. Through technological innovation and creative plan design, MyHealthily is bringing healthcare rates traditionally enjoyed only by Fortune 500 companies to small groups across the country.</p>
						<p>Learn more at <a href="https://myhealthily.com/" target="_new" className="learn-more text-color-primary font-weight-semibold text-2">myhealthily.com<i className="ps-2 fas fa-chevron-right text-2"></i></a></p>
					</div>
					<div className="col-lg-8 mt-5 mt-lg-0">
						<div className="row">
							<div className="col-lg-6 mb-4 mb-lg-5 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="200">
								<div className="feature-box feature-box-style-2">
									<div className="feature-box-icon">
										<i className="icon-fire icons"></i>
									</div>
									<div className="feature-box-info">
										<h4 className="font-weight-bold mb-2">A Total Game Changer</h4>
										<p className="mb-0">Our proprietary software enables agents to offer affordable healthcare insurance and ancillary products to small businesses with 1 – 500+ employees.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-6 mb-4 mb-lg-5 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="400">
								<div className="feature-box feature-box-style-2">
									<div className="feature-box-icon">
										<i className="icon-star icons"></i>
									</div>
									<div className="feature-box-info">
										<h4 className="font-weight-bold mb-2">WOW Customer Support</h4>
										<p className="mb-0">Our team is here to provide product assistance, answer questions, and make your MyHealthily experience great!</p>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-6 mb-4 mb-lg-0">
								<div className="feature-box feature-box-style-2 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="800">
									<div className="feature-box-icon">
										<i className="icon-screen-desktop icons"></i>
									</div>
									<div className="feature-box-info">
										<h4 className="font-weight-bold mb-2">Easy-to-Use Software</h4>
										<p className="mb-0">We’ve developed a straight-forward user experience that makes quoting, enrollment, and renewals a breeze.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="feature-box feature-box-style-2 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="600">
									<div className="feature-box-icon">
										<i className="icon-umbrella icons"></i>
									</div>
									<div className="feature-box-info">
									<h4 className="font-weight-bold mb-2">National Carriers</h4>
										<p className="mb-0">We work with 170+ carriers to bring small businesses benefits including healthcare, dental, vision, Aflac products, and more.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<Helmet>
				<script src="https://cdn.pagesense.io/js/candorusa/4456c48b933f4a539edc609cbc6838fa.js"></script>
				<script src={`${process.env.PUBLIC_URL}vendor/plugins/js/plugins.min.js`}></script>
				<script src={`${process.env.PUBLIC_URL}js/demos/demo-one-page-agency.js`}></script>
			</Helmet>

		</div>
	);
}
	
export default Home;