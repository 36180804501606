import { setTheme, Partners } from "./helpers/partners";
import { Header } from "./components/Header";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Abc from "./pages/abc";
import AbcsSchedule from "./pages/abc-schedule";
import Barrington from "./pages/barrington-2";
import BarringtonSchedule from "./pages/barrington-schedule";
import Insursync from "./pages/insursync";
import InsursyncSchedule from "./pages/insursync-schedule";
import Isdshealth from "./pages/isdshealth";
import IsdshealthSchedule from "./pages/isdshealth-schedule";
import Pca from "./pages/pca-2";
import PcaSchedule from "./pages/pca-schedule";
import Pfpa from "./pages/pfpa";
import PfpaSchedule from "./pages/pfpa-schedule";
import Pin from "./pages/pin";
import PinSchedule from "./pages/pin-schedule";
import ServiceNation from "./pages/service-nation";
import ServiceNationSchedule from "./pages/service-nation-schedule";
import Zinc from "./pages/zincinsuranceagency";
import ZincSchedule from "./pages/zinc-schedule";
import { Footer } from "./components/Footer";

//setTheme('NECSEMAHealthcare')
export const App = () => {
	if(process.env.NODE_ENV === 'development') {
		let params = (new URL(document.location)).searchParams;
		let theme = params.get("theme");
		setTheme(theme);
	}
	console.log(process.env.NODE_ENV)
	
	return <div className={`body ${Partners.theme}`}>
		<Header/>
		<Routes>
			<Route path={"/"} element={<Home/>}/>
			<Route path={"/abc"} element={<Abc/>}/>
			<Route path={"/abc-schedule"} element={<AbcsSchedule/>}/>
			<Route path={"/barrington-2"} element={<Barrington/>}/>
			<Route path={"/barrington-schedule"} element={<BarringtonSchedule/>}/>
			<Route path={"/insursync"} element={<Insursync/>}/>
			<Route path={"/insursync-schedule"} element={<InsursyncSchedule/>}/>
			<Route path={"/isdshealth"} element={<Isdshealth/>}/>
			<Route path={"/isdshealth-schedule"} element={<IsdshealthSchedule/>}/>
			<Route path={"/pca-2"} element={<Pca/>}/>
			<Route path={"/pca-schedule"} element={<PcaSchedule/>}/>
			<Route path={"/pfpa"} element={<Pfpa/>}/>
			<Route path={"/pfpa-schedule"} element={<PfpaSchedule/>}/>
			<Route path={"/pin"} element={<Pin/>}/>
			<Route path={"/pin-schedule"} element={<PinSchedule/>}/>
			<Route path={"/service-nation"} element={<ServiceNation/>}/>
			<Route path={"/service-nation-schedule"} element={<ServiceNationSchedule/>}/>
			<Route path={"/zincinsuranceagency"} element={<Zinc/>}/>
			<Route path={"zinc-schedule/"} element={<ZincSchedule/>}/>
			<Route path="*" element={<div>404</div>}/>
		</Routes>
		<Footer/>
	</div>;
};
